// eslint-disable-next-line import/no-named-default
import { default as pino } from 'pino';
import { datadogLogs } from '@datadog/browser-logs';

export type { Logger } from 'pino';

function getLogLevel(): pino.LevelWithSilent {
  const logLevel = (process.env.LOG_LEVEL || 'info').toLowerCase();
  if (logLevel === 'off') {
    return 'silent';
  } else {
    return logLevel as pino.LevelWithSilent;
  }
}

// @ts-ignore
const rootLogger = pino({
  level: getLogLevel(),
  name: 'customer-ui'
});

export function datadogLogger(name?: string) {
  if (name) {
    let childDDLogger = datadogLogs.getLogger(name);
    if (!childDDLogger) {
      childDDLogger = datadogLogs.createLogger(name);
    }

    return childDDLogger;
  }

  return datadogLogs.logger;
}

export default function logger(name?: string) {
  if (name) {
    return rootLogger.child({ module_name: name });
  } else {
    return rootLogger;
  }
}
